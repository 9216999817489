import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";
import router from "../router/index";
Vue.use(Vuex);

import { EncryptStorage } from "encrypt-storage";
export const encryptStorage = new EncryptStorage("secret-key-value", {
  prefix: "@talabk",
  encAlgorithm: "Rabbit",
});
export default new Vuex.Store({
  state: {
    connection_snack: false,
    drawer: true,
    mini: false,
     def_path: "https://ap.superline.app",
    //def_path: "http://127.0.0.1:8000",
    token: encryptStorage.getItem("token_superline_user") || null,
    superline_user: encryptStorage.getItem("superline_user") || {
      is_company: true,
    },
    userPermissions: {
      data: [],
      done: false,
    },
    companyPermissions: {
      data: [],
      done: false,
    },
    break_point: 0,
    count_active_orders: 0,
  },
  getters: {
    loggedin(state) {
      return state.token != null;
    },
    permission: (state) => (permission) => {
      return state.userPermissions.data.includes(permission);
    },
    first_route(state) {
      let final_per = state.superline_user.permissions.filter((pre) =>
        pre.includes("access")
      );
      if (final_per.length != 0) {
        if (final_per.includes("products access")) {
          return "products";
        } else {
          return final_per[0].split(" ")[0];
        }
      }

      return "problem";
    },
    isCompany(state) {
      return state.superline_user.is_company;
    },
  },
  mutations: {
    login(state, token) {
      state.token = token;
      encryptStorage.setItem("token_superline_user", token);
    },
    user(state, user) {
      state.superline_user = user;
      encryptStorage.setItem("superline_user", user);
    },
    logout(state) {
      state.token = null;
      encryptStorage.removeItem("token_superline_user");
      encryptStorage.removeItem("superline_user");
    },
    setPermissions(state, data) {
      state.userPermissions.data = data.userPermissions;
      state.userPermissions.done = true;
      state.companyPermissions.data = data.companyPermissions;
      state.companyPermissions.done = true;
    },
  },
  actions: {
    login(context, cred) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("phone_number", cred.phone_number);
        formData.append("password", cred.password);
        var config = {
          method: "post",
          url: "login",
          headers: {
            Accept: "application/json",
          },
          data: formData,
        };
        axios(config)
          .then((res) => {
            const data = res.data.data;
            if (!data.otp) {
              context.commit("login", data.token);
              context.commit("user", data);
            }

            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // public request this function handel any requset type like post & get Etc ...
    public__request(context, cred) {
      return new Promise(async (resolve, reject) => {
        let config = {
          method: cred.config.method,
          url: cred.config.url,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${context.state.token}`,
          },
          data: cred.data || null,
        };
        axios(config)
          .then((res) => {
            resolve(res);
            context.state.connection_snack = false;
            if (cred.get) {
              context.commit("public__request", {
                data: res.data,
                name: cred.get,
              });
            }
          })
          .catch((err) => {
            if (!err.response) {
              context.state.connection_snack = true;
            } else {
              reject(err);
            }
            if (err.response.status == 401) {
              context.commit("logout");
              router.push("/");
            }
          });
      });
    },
    async requirements(context) {
      await context
        .dispatch("public__request", {
          config: {
            url: "requirements",
            method: "get",
          },
        })
        .then((res) => {
          let data = res.data.data;
          context.state.count_active_orders = data.activeOrders;
          context.commit("setPermissions", data);
        });
    },
    active_orders(context) {
      context
        .dispatch("public__request", {
          config: {
            url: "active_orders",
            method: "get",
          },
        })
        .then((res) => {
          context.state.count_active_orders = res.data.data;
        });
    },
  },
  modules: {},
});
