<template>
  <div class="header mb-5">
    <v-app-bar
      :app="$store.state.break_point <= 991"
      color="white"
      flat
      height="65"
    >
      <v-container class="py-0 align-center d-flex justify-space-between">
        <h1
          v-if="
            !$store.getters.loggedin || !$store.state.superline_user.is_company
          "
        >
          superline
        </h1>
        <img width="100" height="40" v-else :src="`${$store.state.def_path}/${$store.state.superline_user.logo}`" alt="">
        <v-btn
          v-if="$store.state.break_point <= 991 && $store.getters.loggedin"
          small
          icon
          @click="$store.state.drawer = !$store.state.drawer"
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  data: () => ({}),
  methods: {},
};
</script>
